








import Vue from 'vue';
import AgentBar from './components/AgentBar.vue'

export default Vue.extend({
  name: 'App',
  components: {
    AgentBar
  }
});
