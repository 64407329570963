import Vue from 'vue'
import App from './App.vue'
import {
  Icon,
  Col,
  Row,
  Input,
  Button,
  message,
  Layout,
  Dropdown,
  Menu,
  Modal,
  FormModel,
  Divider,
  Radio,
  Form,
  List
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import {ListItem} from "ant-design-vue/types/list/list-item";

Vue.config.productionTip = false

// const IconFont = Icon.createFromIconfontCN({
//   scriptUrl: '//at.alicdn.com/t/font_1605414_q1lytdo0wc.js',
// });

Vue.component(Layout.name, Layout);
Vue.component(Layout.Content.name, Layout.Content);
Vue.component(Layout.Header.name, Layout.Header);
Vue.component(Layout.Footer.name, Layout.Footer);
Vue.component(Layout.Sider.name, Layout.Sider);
Vue.component(Dropdown.name, Dropdown)
Vue.component(Menu.name, Menu)
Vue.component(Menu.Item.name, Menu.Item)
Vue.use(Modal)
Vue.use(Radio)
Vue.use(Divider)
Vue.component(Form.name, Form)
Vue.component(Form.Item.name, Form.Item)
Vue.component(Icon.name, Icon)
Vue.component(Col.name,Col);
Vue.component(Row.name,Row);
Vue.component(List.name,List);
Vue.component(List.Item.name,List.Item);
Vue.component(List.Item.Meta.name,List.Item.Meta);
Vue.component(Input.name,Input);
Vue.component(Button.name,Button);
Vue.component('message', message)
new Vue({
  render: h => h(App),
}).$mount('#app')
